import { ReactElement, useEffect, useState } from 'react';
import { LoaderSpinner, SoundWaveAnimatedIcon, SoundWaveIcon } from '@components';
import { ArrowDownTrayIcon, PauseCircleIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import { SubjectState } from '@services';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import moment from 'moment';
import { AlertActionsProps } from './AiDetections.props';

export const AlertActions = ({
  alert,
  alertTimestamp,
  frames,
  isAlertAnimating,
  service,
  isPlayingAudio,
  stopAnimationTimer,
  playAlert,
  playAudio,
  stopAudio,
  selectedAudio,
}: AlertActionsProps): ReactElement => {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isPlayingAlertAudio, setIsPlayingAlertAudio] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isDisabled] = useState<boolean>(alertTimestamp < frames[0]?.timeStamp || alertTimestamp > frames[frames.length - 1]?.timeStamp);

  const downloadFrames = () => {
    const zip = new JSZip();
    const date = moment.utc(alert.dateCreated).unix() * 1000;
    const rangeIndex = frames?.findIndex(frame => {
      return frame.timeStamp > date;
    });
    let range = rangeIndex;
    if (rangeIndex - 30 <= 0) {
      range = frames[0]?.timeStamp;
    } else if (rangeIndex + 30 >= frames[frames.length - 1]?.timeStamp) {
      range = frames[frames.length - 1]?.timeStamp;
    }

    const images = frames.slice(range - 30, range + 30).filter(img => img.imageUrl);
    const aiLogs = JSON.stringify(
      frames.slice(range - 30, range + 30).map(frame => frame.info),
      null,
      2
    );

    let count = 0;
    const blob = new Blob([aiLogs], { type: 'application/json' });
    zip?.file('AI-Logs.json', blob);

    setIsDownloading(true);
    if (images.length > 0) {
      images.forEach(img => {
        const imagesFolder = zip?.folder('images');
        JSZipUtils.getBinaryContent(img.imageUrl, (err: unknown, data: string | Blob) => {
          if (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            return;
          }
          imagesFolder?.file(img.imageFile, data, { binary: true });
          count += 1;
          if (count === images.length) {
            zip
              .generateAsync({ type: 'blob' })
              .then(content => {
                saveAs(content, `detections-${moment.utc(alert.dateCreated).format('YYYY-MM-DD HH:mm:ss.SS')}.zip`);
              })
              .finally(() => setIsDownloading(false));
          }
        });
      });
    } else {
      zip
        .generateAsync({ type: 'blob' })
        .then(content => {
          saveAs(content, `detections-${moment.utc(alert.dateCreated).format('YYYY-MM-DD HH:mm:ss.SS')}.zip`);
        })
        .finally(() => setIsDownloading(false));
    }
  };

  const handleToggleAudio = () => {
    if (isPlayingAlertAudio) {
      stopAudio();
      setIsPlayingAlertAudio(false);
    }
    if (alert.id === selectedAudio) {
      return;
    }
    playAudio(alert.id);
    setIsPlayingAlertAudio(true);
  };

  useEffect(() => {
    if (!isAlertAnimating) {
      setIsAnimating(false);
    }
  }, [isAlertAnimating, isAnimating]);

  useEffect(() => {
    if (!isPlayingAudio) {
      setIsPlayingAlertAudio(false);
    }
  }, [isPlayingAudio]);

  return (
    <div className='flex items-center space-x-1'>
      {isAnimating && (
        <button
          type='button'
          className='bg-transparent p-0 border-0 disabled:opacity-75 disabled:cursor-not-allowed hover:cursor-pointer'
          disabled={isDisabled}
          onClick={() => {
            setIsAnimating(false);
            stopAnimationTimer();
          }}>
          <PauseCircleIcon className='w-6 h-6 text-gray-400 dark:text-white transition-all dark:hover:text-gray-300' />
        </button>
      )}
      {!isAnimating && (
        <button
          type='button'
          className='bg-transparent p-0 border-0  disabled:opacity-75 disabled:cursor-not-allowed hover:cursor-pointer'
          disabled={isDisabled}
          onClick={() => {
            playAlert(alert);
          }}>
          <PlayCircleIcon className='w-6 h-6 text-gray-400 dark:text-white transition-all dark:hover:text-gray-300' />
        </button>
      )}
      {isDownloading ? (
        <LoaderSpinner className='mr-2 w-4 h-4' />
      ) : (
        <button
          type='button'
          className='bg-transparent p-0 border-0  disabled:opacity-75 disabled:cursor-not-allowed hover:cursor-pointer'
          disabled={isDisabled}
          onClick={downloadFrames}>
          <ArrowDownTrayIcon className='w-6 h-6 text-gray-400 dark:text-white transition-all dark:hover:text-gray-300' />
        </button>
      )}
      <SubjectState
        subject={service.audioFiles}
        render={audioFiles => (
          <>
            {audioFiles.get(alert.id) && (
              <button
                type='button'
                disabled={isPlayingAudio && alert.id !== selectedAudio}
                className='bg-transparent p-0 border-0  disabled:opacity-50 disabled:cursor-not-allowed hover:cursor-pointer'
                onClick={handleToggleAudio}>
                {!isPlayingAlertAudio ? (
                  <SoundWaveIcon onClick={() => {}} className='text-gray-400 dark:text-white transition-all dark:hover:text-gray-300' />
                ) : (
                  <SoundWaveAnimatedIcon className='text-gray-400 dark:text-white transition-all dark:hover:text-gray-300' />
                )}
              </button>
            )}
          </>
        )}
      />
    </div>
  );
};
