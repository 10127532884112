import { MouseEvent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { CallTypeLabels, ConferenceEndReasonDescriptions, ConferenceEndReasonLabels, ConferenceStatus } from '@enums';
import { CheckCircleIcon, DocumentDuplicateIcon, InformationCircleIcon, MinusCircleIcon, StarIcon } from '@heroicons/react/24/outline';
import { PhoneXMarkIcon } from '@heroicons/react/24/solid';
import { getCapitalizedWords, getDurationBetween, getLowerCaseString, getRatingColor, utcToLocalDateTime } from '@utils';
import { ConferencesItemProps } from './Conferences.props';

export const ConferencesItem = ({ service, conference, handleShowModal, handleGoToConference }: ConferencesItemProps): ReactElement => {
  const handleShowTerminateModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShowModal(conference.id);
  };

  const handleCopyConferenceId = (event: MouseEvent<HTMLButtonElement>, conferenceId: string) => {
    event.stopPropagation();
    navigator.clipboard.writeText(conferenceId);
  };

  return (
    <tr className='text-sm dark:text-gray-300 dark:hover:bg-dark-body dark:hover:text-gray-300 hover:cursor-pointer' onClick={handleGoToConference}>
      <td>
        <span className='flex items-center gap-1'>
          <button id={`conference-copy-${conference.id}`} type='button' onClick={e => handleCopyConferenceId(e, conference.id)}>
            <DocumentDuplicateIcon className='w-4 h-4 text-sky-400 dark:hover:text-sky-600' />
          </button>
          <UncontrolledTooltip placement='top' target={`conference-copy-${conference.id}`}>
            Copy Conference ID
          </UncontrolledTooltip>
          <Link className='no-underline text-sky-400 dark:hover:text-sky-600' to={`/conferences/${service.isOngoing ? 'ongoing' : 'past'}/${conference.id}`}>
            {conference.id.substring(0, 8)}...
          </Link>
        </span>
      </td>
      <td className='text-left'>
        {conference.participants ? (
          conference.participants.find(participant => participant.isInitiator)?.name
        ) : (
          <div className='flex items-center'>
            n/a
            <InformationCircleIcon className='w-6 h-6 text-sky-400' id='initiator' />
            <UncontrolledTooltip placement='top' target='initiator'>
              We weren&apos;t able to determine the initiator of this conference
            </UncontrolledTooltip>
          </div>
        )}
        {conference.hasErrors && (
          <>
            <Badge pill id={`HasError_${conference.id}`} className='ml-2' color='danger'>
              !
            </Badge>
            <UncontrolledTooltip placement='top' target={`HasError_${conference.id}`}>
              This conference has errors
            </UncontrolledTooltip>
          </>
        )}
      </td>
      <td className='text-left'>{getCapitalizedWords(CallTypeLabels[conference.callType])}</td>
      <td>{utcToLocalDateTime(conference.startedAt, service.timezone.value)}</td>
      {!service.isOngoing && <td>{utcToLocalDateTime(conference.endedAt, service.timezone.value)}</td>}
      <td className='text-left'>{getDurationBetween(conference.startedAt, conference.endedAt)}</td>
      <td className='text-left'>
        {service.isOngoing &&
          conference.participants &&
          `${conference.participants?.filter(participant => participant.state === 1).length} / ${conference.participants?.length}`}
        {!service.isOngoing && conference.participants && conference.participants.length}
        {!conference.participants && (
          <div className='flex items-center'>
            n/a
            <InformationCircleIcon className='w-6 h-6 text-sky-400' id='participants' />
            <UncontrolledTooltip target='participants'>We weren&apos;t able to determine the participants in this conference</UncontrolledTooltip>
          </div>
        )}
      </td>
      {!service.isOngoing && (
        <td className='text-left '>
          <span className='flex items-center justify-between'>
            <p className='mb-0'>{getLowerCaseString(ConferenceEndReasonLabels[conference.endReason])}</p>
            <InformationCircleIcon className='w-6 h-6 text-sky-400' id={`HasError_${conference.id}`} />
            <UncontrolledTooltip placement='top' target={`HasError_${conference.id}`}>
              {getLowerCaseString(ConferenceEndReasonDescriptions[conference.endReason])}
            </UncontrolledTooltip>
          </span>
        </td>
      )}
      {!service.isOngoing && (
        <>
          <td>{getLowerCaseString(ConferenceStatus[conference.status])}</td>
          <td align='center'>
            {conference.usedAudio ? <CheckCircleIcon className='w-6 h-6 text-green-400 ' /> : <MinusCircleIcon className='w-6 h-6 text-gray-400' />}
          </td>
          <td align='center'>
            {conference.usedVideo ? <CheckCircleIcon className='w-6 h-6 text-green-400' /> : <MinusCircleIcon className='w-6 h-6 text-gray-400' />}
          </td>
          <td align='center'>
            {conference.usedScreen ? <CheckCircleIcon className='w-6 h-6 text-green-400' /> : <MinusCircleIcon className='w-6 h-6 text-gray-400' />}
          </td>
          <td align='center'>
            {conference.isUsingMediaServer ? (
              <CheckCircleIcon className='w-6 h-6 text-green-400 text-right flex' />
            ) : (
              <MinusCircleIcon className='w-6 h-6 text-gray-400 mr-0 flex justify-end items-end' />
            )}
          </td>
          <td align='center'>
            {conference.rate && (
              <div className='flex items-center'>
                {[1, 2, 3, 4, 5]
                  .map(item => (
                    <StarIcon
                      key={item}
                      className='w-6 h-6 text-yellow-300'
                      fill={(conference.rate || 0) >= item ? getRatingColor(conference.rate || 0) : '#fff'}
                      stroke={getRatingColor(conference.rate || 0)}
                    />
                  ))
                  .reverse()}
              </div>
            )}
            {!conference.rate && 'N/A'}
          </td>
          <td align='center' className='whitespace-pre-line'>
            {conference.iceServerUsage ?? 'N/A'}
          </td>
        </>
      )}
      {service.isOngoing && (
        <td align='center'>
          <button type='button' onClick={handleShowTerminateModal}>
            <PhoneXMarkIcon className='w-6 h-6 text-red-400' />
          </button>
        </td>
      )}
    </tr>
  );
};
